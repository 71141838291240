.App {
  text-align: center;
  padding-top: 5vh;
}

#root{
  background-color: rgba(245, 245, 245) !important;
  height: 100vh;
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: cursive;
}

.header-h2 {
  font-family: cursive;
  margin-left: 1em;
}

@media only screen and (max-width: 600px) {
  .header, .header-h2, .dropdown-item {
      font-size: 0rem;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.daynumber{
  color: #884;
  font-style: italic;
  font-size: smaller;
}


#cra-period-selector select {
  padding: 12px;
  width: 250px;
  border: none;
  font-size: 20px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  -webkit-appearance: button;
  appearance: button;
  outline: none;
}

#cra-period-selector option {
  background-color: white;
  color: #0563af;
}

#cra-period-selector select, #cra-period-selector .selected-period {
  background-color: #0563af;
  color: white;
}

#cra-period-selector select option {
  padding: 30px;
}



@media print {
  /* Set body margin to 0 */
  body {
    margin: 0;
    padding: 0;
  }

  /* For specific sections */
  #printable {
    margin: 0; /* Ensure no margin in the printable area */
    padding: 0;
  }

  /* Use pagebreak properties to force content positioning */
  .no-pagebreak {
    page-break-inside: avoid;
    margin: 0;
    padding: 0;
  }
}
